import Search from "@components/new_search";

export function defineProp(context, prop, callback = () => {}) {
  let _value;
  Object.defineProperty(context, prop, {
    set(newValue) {
      const oldValue = _value;
      _value = newValue;
      callback(newValue, oldValue);
    },
    get() {
      return typeof _value === "object" ? _value.value : _value;
    }
  });
}

export function initMarkup(context, { search, template }) {
  const wrapper = context.$el;
  const inner = document.createElement("div");
  inner.setAttribute("data-inner", "");

  if (!inner.querySelector("[data-option='current']")) {
    inner.insertAdjacentHTML("beforeEnd", template.currentOption);
  }
  inner.insertAdjacentHTML('beforeEnd', `
    <div class="pt-1" data-items>
      ${search ? `<div class="group input__wrapper relative flex items-center mt-2 mb-4 mx-6">
        <input type="text" placeholder="Поиск по брендам" class="h-16 w-full pr-[42px] pl-[44px] py-2 border border-grey-medium outline-none rounded-md text-lg placeholder:text-grey lg:placeholder:text-grey-medium">
        <svg class="absolute left-4 top-2 stroke-grey-medium" width="32" height="32">
          <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#search"></use>
        </svg>
        
        <svg class="absolute hidden group-[.js-active-clear]:flex right-[10px] top-[8px] cursor-pointer opacity-50 hover:opacity-100 stroke-red transition-all" width="24" height="24" data-action="clearInput">
          <use xlink:href="/img/icons.svg?v=${SVG_VERSION}#clear"></use>
        </svg>
      </div>` : ""}
      <div class="scroll mr-6">
        ${getNotFoundItem(search)}
      </div>
    </div>`);

  if (!inner.querySelector("[data-option='current'] [data-icon]")) {
    inner.querySelector("[data-option='current']").insertAdjacentHTML("beforeEnd", `
      ${template.arrow}
    `);
  }

  wrapper.appendChild(inner);
}

export function getNotFoundItem(isSearchable) {
  if (!isSearchable) return "";
  return `<div class="notfound--item hidden px-6 flex items-center justify-center h-[var(--height,30px)]">Ничего не найдено</div>`;
}

export function initSearch(container) {
  const search = new Search(document.querySelector(".input__wrapper input"), { container: ".input__wrapper" });
  let timer;

  search.on("input", setList);
  search.on("clearInput", setList)

  function setList(evt) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      const items = container.querySelectorAll("[data-inner] .scroll > div[data-title]:not([data-option=selected])");
      const notfoundItem = container.querySelector("[data-inner] .scroll > .notfound--item");

      const reg = new RegExp(`((^| )${search.input.value})`, "i");
      let isFind = false;

      requestAnimationFrame(() => {
        items.forEach((item) => {
          const isShow = reg.test(item.dataset.title);
          if (!isFind && isShow) isFind = true;

          const searchText = item.innerText.replace(/\n/g, "").replace(reg, `<span class="text-red">$&</span>`);
          item.innerHTML = `<span>${searchText}</span>`;
          item.classList[isShow ? "remove" : "add"]("hidden");
        });

        notfoundItem.classList[isFind ? "add" : "remove"]("hidden");
      });
    }, 400);
  }

  return search;
}

export function objToHtmlEl(option, options = {}) {
  const el = document.createElement("div");

  if (options.search) el.setAttribute("data-title", option.text);
  el.setAttribute("data-value", option.value);
  el.setAttribute("data-option", getDataOptionVal(option));
  el.innerHTML = `<span>${option.text}</span>`;
  return el;
}

export function htmlToObj(selectChildren) {
  return [...selectChildren].map((el) => {
    return {
      value: el.value,
      text: el.innerText,
      selected: el.selected ?? false,
      disabled: el.disabled ?? false,
    };
  });
}

export function getDataOptionVal(option) {
  return option.selected ? 'selected' : (option.disabled ? 'disabled' : '');
}

export function handleOptionWrapperPadding(context) {
  const itemsEl = context.$el.querySelector("[data-items]");
  const maxItemsCount = parseInt(getCSSVariable(context.$el, "--items-per-list"));

  if (context.options.size > maxItemsCount) {
    itemsEl.classList.remove("no-padding-right");
  } else {
    itemsEl.classList.add("no-padding-right");
  }
}

const getCSSVariable = (el, varName) => {
  return window.getComputedStyle(el).getPropertyValue(varName);
}

export const defaultOption = {
  selected: false,
  disabled: false,
  search: false,
  value: undefined,
  $el: undefined,
};

export const defaultSelectOptions = {
  disabled: false,
  template: {
    currentOption: `
      <div data-option="current">
        <span class="line-clamp-1">Стандартное значение</span>
      </div>
    `,
    arrow: `
      <svg class="stroke-grey" width="24" height="24" data-icon="">
        <use xlink:href="/img/icons/sprite.svg?v=${SVG_VERSION}#arrow_new_24"></use>
      </svg>
    `,
  },
  vars: {},
};