import { Emitter } from "./emitter.js";
import { initSearch, getNotFoundItem } from './utils/utils.js';
import { defineProp, defaultOption, objToHtmlEl, initMarkup, htmlToObj, defaultSelectOptions, handleOptionWrapperPadding } from "./utils/utils.js";

export default class Select extends Emitter {
  $el;
  $Search;
  value;
  disabled;
  options;
  config;
  parent = [];
  children = [];

  constructor($el, opts) {
    super();
    let created = false;

    if (opts.name) {
      this.name = opts.name;
    }

    opts = {
      ...defaultSelectOptions,
      ...opts,
      template: {
        ...defaultSelectOptions.template,
        ...opts.template,
      }
    };

    this.config = opts;

    this.vars = opts.vars;

    this.options = new Map();
    this.$el = $el;

    defineProp(this, "value", (newValue, oldValue) => {
      let { value, type } = newValue;
      if (typeof newValue !== 'object') {
        value = newValue;
        type = "direct";
      }

      if (this.disabled || value === oldValue?.value) {
        return;
      }

      const selectedOption = this.options.get(String(value));

      if (selectedOption) {
        this.options.forEach((option) => {
          if(option.value !== String(value)) {
            option.selected = false;
            option.$el.setAttribute("data-option", "");
          }
        });

        this.$el.querySelector("[data-option='current'] span").innerText = selectedOption.text;
        selectedOption.$el.innerHTML = `<span>${selectedOption.text}</span>`;
        selectedOption.$el.setAttribute("data-option", "selected");
        selectedOption.selected = true;
        this.$Search?.clearInput();

        this.selectEl.value = value;
        if (created) {
          let event = new Event("input", { bubbles: true });
          this.selectEl.dispatchEvent(event);
        }

        this.emit("change", selectedOption, type);
      }
    });

    defineProp(this, "disabled", (newValue, oldValue) => {
      const label = this.$el.closest("label"); 
      
      if (newValue === true) {
        this.$el.setAttribute("disabled", "");
        label?.setAttribute("disabled", "true");
      } else if (newValue === false) {
        this.$el.removeAttribute("disabled");
        label?.removeAttribute("disabled");
      }
    });

    initMarkup(this, opts);
    this.#initSearch();

    this.selectEl = this.$el.querySelector("select");
    this.add(htmlToObj(this.selectEl.children));
    created = true;

    if (opts.value !== undefined) {
      this.value = opts.value;
    }

    document.addEventListener("click", (event) => {
      if (event.target.closest("[data-select]") !== this.$el) {
        this.close();
        return;
      }

      const clickedOption = event.target.closest("[data-option]");
      if (
        !clickedOption ||
        clickedOption.dataset.option === "disabled" ||
        this.$el.getAttribute("disabled") !== null
      ) {
        return;
      }

      if (clickedOption.dataset.option === "current") {
        this.$el.getAttribute("data-open") !== null ? this.close() : this.open();
        return;
      }

      if (clickedOption.dataset.option !== null) {
        this.value = { value: clickedOption.dataset.value, type: "click" };
        this.close();
      }
    });
  }

  #initSearch() {
    if (this.config.search) {
      this.$Search = initSearch(this.$el);
      this.$Search?.emit("clear")
    }
  }

  add(option) {
    if (!Array.isArray(option)) {
      option = [option];
    }

    option.forEach((opt) => {
      if (typeof opt !== "object") {
        opt = {
          ...defaultOption,
          value: opt,
          text: opt,
        };
      }

      opt.value = String(opt.value);

      if (this.options.has(opt.value)) {
        return;
      }

      const _option = {
        ...defaultOption,
        ...opt,
      };

      _option.$el = objToHtmlEl(_option, this.config);

      this.$el.querySelector("[data-items] > .scroll").appendChild(_option.$el);
      this.options.set(opt.value, _option);

      if (_option.selected) {
        this.value = { value: _option.value, type: "add" };
      }
    });

    handleOptionWrapperPadding(this);
  }

  remove(value) {
    value = String(value);
    this.$el.querySelector(`[data-option][data-value='${value}']`)?.remove();
    this.options.delete(value);
    handleOptionWrapperPadding(this);
  }

  set(option) {
    this.clear();
    this.#initSearch()
    this.add(option);
  }

  clear() {
    this.$el.querySelector("[data-items] .scroll").innerHTML = getNotFoundItem(this.config.search);
    this.options.clear();
    handleOptionWrapperPadding(this);
  }

  item(index) {
    index = Math.max(0, Math.min(index, this.options.size - 1));
    const [key, value] = [...this.options][index];
    return value;
  }

  itemByValue(value) {
    return this.options.get(value);
  }

  open() {
    this.$el.setAttribute("data-open", "");
    handleOptionWrapperPadding(this);
    this.emit("open");
  }

  close() {
    this.$el.removeAttribute("data-open");
    this.emit("close");
  }
}